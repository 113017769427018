<template>
  <div class="treatedStats" style="height: 100%">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <nav class="navbar navbar-expand-lg">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 0 15px">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>医疗专属服务</el-breadcrumb-item>
          <el-breadcrumb-item>转诊统计</el-breadcrumb-item>
        </el-breadcrumb>
        <head-center></head-center>
      </nav>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <el-tabs v-model="activeName" class="stats-tab" style="height: 100%">
          <el-tab-pane label="本院接诊统计" name="selfTreated" style="height: 100%">
            <el-form :inline="true" :model="searchForm" class="clearfix">
              <el-col :span="22">
                <el-form-item label="医院科室">
                  <!-- <el-select v-model="searchForm.departmentId" size="small" placeholder="请选择科室" @change="search">
                    <el-option label="全部" value="0"></el-option>
                    <el-option v-for="item in departmentList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select> -->
                  <el-cascader :options="departmentList" :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" size="small"></el-cascader>
                </el-form-item>
                <el-form-item label="选择时间">
                  <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search"> </el-date-picker>
                </el-form-item>
                <el-form-item label="患者状态">
                  <el-select v-model="searchForm.status" size="small" @change="search">
                    <el-option label="全部" value="999"></el-option>
                    <el-option label="待处理" value="-1"></el-option>
                    <el-option label="处理中" value="1"></el-option>
                    <el-option label="已完成" value="-2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="患者姓名">
                  <el-input v-model="searchForm.keyWord" placeholder="请输入患者姓名" size="small" @keyup.enter.native="search" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-form-item>
                  <el-button type="primary" @click="search()" size="small">查询</el-button>
                </el-form-item>
              </el-col>
            </el-form>
            <el-table :data="receptionTableData" stripe style="width: 100%" height="calc(100% - 110px)">
              <el-table-column prop="receptionTime" label="接诊时间" width="160">
                <template slot-scope="scope">
                  <p>{{ scope.row.receptionTime | timefilters }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" align="center"></el-table-column>
              <el-table-column prop="sex" label="性别" :formatter="ReferralSex" align="center"></el-table-column>
              <el-table-column prop="age" label="年龄" align="center"></el-table-column>
              <el-table-column prop="diagnosis" label="初步诊断" align="center"></el-table-column>
              <!-- <el-table-column prop="receptionHospitalName" label="接诊医院" align="center"></el-table-column> -->
              <el-table-column prop="receptionDermant" label="接诊科室" align="center"></el-table-column>
              <el-table-column label="接诊医生" width="200" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.receptionName }}</p>
                  <span v-if="scope.row.receptionPhone">【{{ scope.row.receptionPhone }}】</span>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="referralHospitalName" label="转诊医院" align="center"></el-table-column> -->
              <el-table-column prop="referralDermant" label="转诊科室" align="center"></el-table-column>
              <el-table-column label="转诊医生" width="200" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.referralName }}</p>
                  <span v-if="scope.row.referralPhone">【{{ scope.row.referralPhone }}】</span>
                </template>
              </el-table-column>
              <el-table-column prop="state" label="状态" width="120" :formatter="ReferralState" align="center"></el-table-column>
              <el-table-column prop="referralTime" label="转诊时间" width="160" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.referralTime | timefilters }}</p>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="ReceptionTotal" layout="total, prev, pager, next, jumper"></el-pagination>
          </el-tab-pane>
          <!-- <el-tab-pane label="下级医院接诊统计" name="childrenTreated" style="height: 100%">
            <el-form :inline="true" :model="searchForm" class="clearfix">
              <el-col :span="22">
                <el-form-item label="医院科室">
                  <el-select v-model="searchForm.hospitalId" size="small" placeholder="请选择医院" @change="search">
                    <el-option label="全部" value="0" style="margin-right: 20px"></el-option>
                    <el-option v-for="item in organizationList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                  <el-select v-model="searchForm.departmentId" size="small" placeholder="请选择科室" @change="search">
                    <el-option label="全部" value="0"></el-option>
                    <el-option v-for="item in departmentList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="选择时间">
                  <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search"> </el-date-picker>
                </el-form-item>
                <el-form-item label="患者状态">
                  <el-select v-model="searchForm.status" size="small">
                    <el-option label="全部" value="999"></el-option>
                    <el-option label="待处理" value="-1"></el-option>
                    <el-option label="处理中" value="1"></el-option>
                    <el-option label="已完成" value="-2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="患者姓名">
                  <el-input v-model="searchForm.keyWord" placeholder="请输入患者姓名" size="small" @keyup.enter.native="search" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-form-item>
                  <el-button type="primary" @click="search()" size="small">查询</el-button>
                </el-form-item>
              </el-col>
            </el-form>
            <el-table :data="childrenreceptionTableData" stripe style="width: 100%" height="calc(100% - 170px)">
              <el-table-column prop="receptionTime" label="接诊时间" width="160">
                <template slot-scope="scope">
                  <p>{{ scope.row.receptionTime | timefilters }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" align="center"></el-table-column>
              <el-table-column prop="sex" label="性别" :formatter="ReferralSex" align="center"></el-table-column>
              <el-table-column prop="age" label="年龄" align="center"></el-table-column>
              <el-table-column prop="diagnosis" label="初步诊断" align="center"></el-table-column>
              <el-table-column prop="receptionHospitalName" label="接诊医院" align="center"></el-table-column>
              <el-table-column prop="receptionDermant" label="接诊科室" align="center"></el-table-column>
              <el-table-column label="接诊医生" width="180" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.receptionName }}</p>
                  <span v-if="scope.row.receptionPhone">【{{ scope.row.receptionPhone }}】</span>
                </template>
              </el-table-column>
              <el-table-column prop="referralHospitalName" label="转诊医院" align="center"></el-table-column>
              <el-table-column prop="referralDermant" label="转诊科室" align="center"></el-table-column>
              <el-table-column label="转诊医生" width="190" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.referralName }}</p>
                  <span v-if="scope.row.referralPhone">【{{ scope.row.referralPhone }}】</span>
                </template>
              </el-table-column>
              <el-table-column prop="state" label="状态" width="120" :formatter="ReferralState" align="center"></el-table-column>
              <el-table-column prop="referralTime" label="转诊时间" width="160" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.referralTime | timefilters }}</p>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="childrenReceptionTotal" layout="total, prev, pager, next, jumper"></el-pagination>
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { ReferralStats } from '../../../components/Referral/Referralstats'
import { Organization } from '../../../components/HospitalDomain/Organization'
import { Department } from '../../../components/HospitalDomain/Department'
export default {
  name: 'Treated',
  data() {
    var referral = new ReferralStats(this.TokenClient, this.Services.Referral)
    var organization = new Organization(this.TokenClient, this.Services.Authorization)
    var department = new Department(this.TokenClient, this.Services.Authorization)
    return {
      referralDomain: referral,
      activeName: 'selfTreated',
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      searchForm: {
        hospitalId: '0',
        departmentId: '0',
        startTime: '',
        endTime: '',
        status: '999',
        keyWord: '',
        datepicker: '',
      },
      startTime: '',
      endTime: '',
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 10,
      PageSize2: 10,
      ReceptionTotal: 0,
      childrenReceptionTotal: 0,
      receptionTableData: [],
      childrenreceptionTableData: [],
      organizationDoamin: organization,
      organizationList: [],
      departmentDomain: department,
      departmentList: [],
    }
  },
  //注册局部过滤器
  filters: {
    timefilters(val) {
      if (val == null || val == '') {
        return '暂无时间'
      } else {
        let d = new Date(val) //val 为表格内取到的后台时间
        let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
        let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
        let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
        let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
        let sec = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()
        let times = d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec
        return times
      }
    },
  },
  mounted() {
    this.LoadReceptionList()
    // this.LoadChildrenReceptionList()
    // this.GetOrganizations()
    this.GetDepartments()
  },
  methods: {
    handleOption(e) {
      this.searchForm.departmentId = e[e.length - 1]
      this.PageIndex = 1
      this.LoadReceptionList()
    },
    //角色类型状态
    ReferralSex(row) {
      return row.sex == 0 ? '女' : '男'
    },
    //状态 对应状态码
    ReferralState(row) {
      if (row.state == '0') {
        return '待转诊'
      } else if (row.state == '10') {
        return '处理中'
      } else if (row.state == '-1') {
        return '待转诊'
      } else if (row.state == '11') {
        return '已完成'
      }
    },
    GetDepartments() {
      let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
      var _this = this
      _this.departmentDomain.GetDepartment(
        organizationId,
        function (data) {
          _this.departmentList = data.data
        },
        function (err) {
          _this.departmentList = []
        }
      )
    },
    GetOrganizations() {
      var _this = this
      _this.organizationDoamin.Hospital(
        this.userInfo.productId,
        '',
        function (data) {
          _this.organizationList = data.data
        },
        function (data) {}
      )
    },
    LoadReceptionList() {
      var _this = this
      var item = _this.searchForm
      _this.receptionTableData = []
      _this.referralDomain.ReceptionList(
        _this.startTime,
        _this.endTime,
        item.departmentId,
        _this.pageIndex,
        item.status,
        item.keyWord,
        function (data) {
          _this.receptionTableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageTotal = data.data.pageCount
          _this.PageSize = data.data.pageSize
          _this.ReceptionTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    LoadChildrenReceptionList() {
      var _this = this
      var item = _this.searchForm
      _this.childrenreceptionTableData = []
      _this.referralDomain.ChildrenReceptionList(
        _this.startTime,
        _this.endTime,
        item.hospitalId,
        item.departmentId,
        _this.pageIndex,
        item.status,
        item.keyWord,
        function (data) {
          _this.childrenreceptionTableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageTotal = data.data.pageCount
          _this.PageSize2 = data.data.pageSize
          _this.childrenReceptionTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    search() {
      this.PageIndex = 1
      this.startTime = this.searchForm.datepicker ? this.searchForm.datepicker[0] + ' 00:00:00' : null
      this.endTime = this.searchForm.datepicker ? this.searchForm.datepicker[1] + ' 23:59:59' : null
      if (this.activeName == 'selfTreated') {
        this.LoadReceptionList()
      } else if (this.activeName == 'childrenTreated') {
        this.LoadChildrenReceptionList()
      }
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.startTime = this.searchForm.datepicker ? this.searchForm.datepicker[0] + ' 00:00:00' : null
      this.endTime = this.searchForm.datepicker ? this.searchForm.datepicker[1] + ' 23:59:59' : null
      if (this.activeName == 'selfTreated') {
        this.LoadReceptionList()
      } else if (this.activeName == 'childrenTreated') {
        this.LoadChildrenReceptionList()
      }
    },
  },
}
</script>

<style scoped>
.navbar-expand-lg {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar {
  position: relative;
  height: 73px;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header {
  flex: 1;
  text-align: right;
  margin-right: 15px;
}

.messages {
  margin-top: 13px;
  margin-right: 20px;
}

.messages .el-icon-bell {
  font-size: 26px;
}

/*content*/
.bg-1 {
  background: #8693f3;
}

.bg-2 {
  background: #bc8dee;
}

.bg-3 {
  background: #ffa897;
}

.bg-4 {
  background: #89c3f8;
}

.bg-1:hover {
  box-shadow: 2px 3px 6px rgba(134, 147, 243, 0.5);
}

.bg-2:hover {
  box-shadow: 2px 3px 6px rgba(188, 141, 238, 0.5);
}

.bg-3:hover {
  box-shadow: 2px 3px 6px rgba(255, 168, 151, 0.5);
}

.bg-4:hover {
  box-shadow: 2px 3px 6px rgba(137, 195, 248, 0.5);
}

.iq-card-mes {
  color: #ffffff;
}

.iq-card-mes h4 {
  font-size: 16px;
  font-weight: normal;
}

.iq-card-mes h3 {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
}

.selYearBox {
  position: absolute;
  right: 18px;
  top: -15px;
  padding: 0 20px;
  background: #ffffff;
}
</style>
<style>
.treatedStats .el-tabs__content {
  height: calc(100% - 40px);
}
</style>
